// Wärtsilä Online translations exported from Salesforce. Please see "Translations" in Confluence for correct workflow.
import { ITranslations } from "../../localization";

// tslint:disable-next-line: variable-name
export const main_es_MX: ITranslations = {
  es_MX: {
    "account.accountSelected": "Cuenta seleccionada",
    "account.selectingAccount": "Selección de cuenta...",
    "addAttachment": "Agregar un archivo adjunto",
    "addFilter": "Agregar filtro",
    "addInstallation": "Agregar instalación",
    "addInstallation.nameIsRequired": "Se requiere nombre",
    "addInstallation:energy": "Agregar planta de energía",
    "addInstallation:marine": "Agregar embarcación",
    "applyFilter": "Aplicar filtro",
    "attachments": "Archivos adjuntos",
    "auth.continueToUseOS": "Continuar para usar Wärtsilä en línea",
    "auth.logout": "Cerrar sesión",
    "auth.readOnlyMode": "Modo de sólo lectura",
    "auth.redirectingToLogin": "Redireccionando a inicio de sesión...",
    "auth.sessionExpired": "Se alcanzó el límite de tiempo de espera de sesión, será redirigido para iniciar sesión en la página",
    "auth.sessionWillExpire": "Su sesión expirará pronto",
    "button.cancel": "Cancelar",
    "button.close": "Cerrar",
    "button.next": "Siguiente",
    "button.previous": "Anterior",
    "button.saveForLater": "Guardar para más tarde",
    "button.send": "Enviar",
    "button.skip": "Omitir",
    "button.updateDraft": "Actualizar borrador",
    "cancel": "Cancelar",
    "change": "Cambiar",
    "changePasswordDialog.success": "El correo de instrucciones fue enviado con éxito.",
    "chooseDocumentType": "Filtrar documentos",
    "chooseFilter": "Elegir filtro...",
    "clearFilters": "Borrar filtros",
    "comment.commentIsRequired": "Se requiere comentario",
    "comment.postComment": "Publicar comentario",
    "comment.replyComment": "Responder",
    "comment.writeYourComment": "Escriba su comentario",
    "comment.writeYourReply": "Escriba su respuesta",
    "comment.you": "Usted",
    "commenting": "Comentar",
    "contact": "Contacto",
    "contact.contactWartsilaCustomerSupport": "Póngase en contacto con el servicio de atención al cliente de Wärtsilä",
    "contact.subject": "Asunto",
    "contact.subjectIsRequired": "Se requiere asunto",
    "contact.yourMessage": "Su mensaje",
    "contact.yourMessageIsRequired": "Se requiere mensaje",
    "contactCustomerSupport": "Póngase en contacto con atención al cliente",
    "contextHelp": "Visita rápida",
    "customerSupportTicket": "Boleto de atención al cliente",
    "date": "Fecha",
    "delete": "Borrar",
    "didYouMean": "Quiso decir:",
    "dismissAll": "Descartar todos",
    "download.downloadReady": "¡Descarga lista!",
    "download.equipmentNotFound": "No se pudo encontrar el artículo para el producto",
    "download.error": "Hubo un error con la descarga.",
    "download.preparingDownload": "Preparando su descarga",
    "draft.confirmDelete": "¿Está seguro que desea borrar este borrador?",
    "draft.confirmDeleteTitle": "¿Eliminar borrador?",
    "draft.continueEditing": "Continuar editando",
    "draft.delete": "Eliminar borrador",
    "edit": "Editar",
    "engine": "Motor",
    "equipment": "Producto",
    "equipment.configuration": "Configuración",
    "equipment.nickname": "Sobrenombre de producto",
    "equipment.performanceData": "Desempeño",
    "equipment.title": "Título",
    "equipment.validation.nicknameTooLong": "La longitud máxima para el apodo es {maxLength}",
    "equipmentNotFound": "No existen productos",
    "error.networkError": "Error de red",
    "error.networkErrorPleaseCheckConnectivity": "Por favor revise su conectividad.",
    "error.readOnlyErrorMsg": "Usted está en modo de sólo lectura.",
    "error.readOnlyErrorTitle": "Error de sólo lectura",
    "fileUpload.attachmentRejected": "Archivo adjunto rechazado. Por favor, verifique los límites de carga.",
    "fileUpload.noContactPerson": "No hay persona de contacto. ¡No se puede enviar el mensaje!",
    "fileUpload.spareParts.limit": "Tamaño de archivo de carga máximo: 5MB",
    "fileUpload.uploadAborted": "Se canceló la carga del archivo",
    "fileUpload.uploadFailed": "Falló la carga del archivo",
    "fileUpload.userHasReadOnlyAccess": "El usuario tiene acceso de sólo lectura",
    "filterRequests": "Solicitudes de filtro",
    "iDontKnow": "No lo sé",
    "index.installationDetails": "Detalles de instalación",
    "installation": "Instalación",
    "installation.classificationSociety": "Sociedad de clasificación",
    "installation.deliveryDate": "Fecha de entrega",
    "installation.description": "Descripción",
    "installation.imo": "IMO",
    "installation.inspectionDate": "Fecha de inspección",
    "installation.name": "Nombre",
    "installation.nextPlannedMaintenance": "Siguiente mantenimiento planeado",
    "installation.type": "Tipo",
    "installation:energy": "Planta de energía",
    "installation:marine": "Embarcación",
    "installations": "Instalaciones",
    "installations:energy": "Plantas de energía",
    "installations:marine": "Embarcaciones",
    "language": "Idioma",
    "language.languageNotFound": "No se pudo encontrar idioma con el código de idioma",
    "languageDisclaimer": "Enviar esta solicitud en inglés acelerará el proceso de manejo",
    "letsStart": "Comencemos",
    "loading": "Cargando...",
    "logout": "Cerrar sesión",
    "main.loadMore": "Cargar más",
    "main.pageNotFound": "No se encontró el URL que siguió: {url}",
    "main.pageNotFoundTitle": "No encontrado",
    "manufacturerSerialNumber": "Número de serie del fabricante",
    "month.april": "Abril",
    "month.august": "Agosto",
    "month.december": "Diciembre",
    "month.february": "Febrero",
    "month.january": "Enero",
    "month.july": "Julio",
    "month.june": "Junio",
    "month.march": "Marzo",
    "month.may": "Mayo",
    "month.november": "Noviembre",
    "month.october": "Octubre",
    "month.september": "Septiembre",
    "months": "meses",
    "myFleet": "Mis instalaciones",
    "myFleet:marine": "Mi flota",
    "myInstallations": "Mis instalaciones",
    "myInstallations:energy": "Mis plantas de energía",
    "myInstallations:marine": "Mis embarcaciones",
    "myProfile": "Mi perfil",
    "news.furtherQuestions": "Comentarios o preguntas adicionales",
    "news.productsAreRequired": "Se requiere(n) producto(s)",
    "news.quotationRequestSent": "Solicitud de cotización enviada.",
    "news.readMore": "Leer más",
    "news.requestQuotation": "Solicitar cotización",
    "news.selectTheProductsForTheQuotation": "Seleccione el (los) producto(s) para la cotización",
    "news.sendingQuotationRequest": "Enviando solicitud de cotización.",
    "news.youAreAskingQuotationFor": "Está solicitando cotización para {productName}.",
    "nickname": "Sobrenombre",
    "no": "No",
    "noDocumentsFound": "No se encontraron documentos para",
    "noVisibleInstallationsFound": "No ha definido ningún {sector} visible",
    "noVisibleInstallationsFoundHelp": "Puede hacerlo desde {sector}",
    "notApplicable": "No aplicable",
    "notificationList.markedNotificationsAsRead": "{count} notificaciones marcadas como leídas",
    "notificationList.title.criticalBulletinUpdated": "Boletín para acción inmediata: {title}",
    "notificationList.title.equipmentRunningHoursUpdate": "Se deben actualizar las horas de operación",
    "notificationList.title.manualUpdated": "Se actualizó el capítulo para el manual: {title}",
    "notificationList.title.newAttachment": "Nuevo anexo",
    "notificationList.title.newCMReport": "Nuevo reporte",
    "notificationList.title.newComment": "Nuevo comentario",
    "notificationList.title.newRequest": "Se creó nueva solicitud",
    "notificationList.title.newSalesOrder": "Nuevo pedido de ventas",
    "notificationList.title.newServiceQuotation": "Nueva cotización de servicio",
    "notificationList.title.newSparePartClaim": "Nuevo reclamo de parte de repuesto",
    "notificationList.title.resolvedSparePartClaim": "Reclamo de parte de repuesto resuelto",
    "notificationList.title.salesOrderConfirmed": "Se confirmó el pedido de ventas",
    "notificationList.title.serviceQuotationQuoted": "Se realizó la cotización de servicio",
    "notificationList.title.techRequestSolutionPlan": "Nuevo plan de solución agregado",
    "notificationList.title.waitingForRating": "Esperando clasificación",
    "notificationList.title.waitingForReply": "Esperando respuesta",
    "notificationList.title.warrantyContactUpdated": "Contacto de garantía actualizado",
    "notifications.lastWeek": "Semana pasada",
    "notifications.markAllRead": "Marcar todos como leídos",
    "notifications.older": "Anteriores",
    "notifications.thisWeek": "Esta semana",
    "notifications.title": "Notificaciones ({unreadNotifications})",
    "notifications.today": "Hoy",
    "notifications.yesterday": "Ayer",
    "ok": "OK",
    "optionalFieldTitle": "opcional",
    "orderBeingProcessed": "El pedido se está procesando",
    "phone": "Teléfono",
    "pleaseAddInstallation": "¡Por favor seleccione una instalación!",
    "pleaseAddInstallation:energy": "¡Por favor seleccione una planta de energía!",
    "pleaseAddInstallation:marine": "¡Por favor seleccione una embarcación!",
    "productNumber": "Número de producto",
    "productType": "Tipo de producto",
    "profile.updating": "Actualizando el perfil...",
    "quotationBeingProcessed": "La cotización se está procesando",
    "removeInstallation": "Eliminar instalación",
    "removeInstallation:energy": "Eliminar planta de energía",
    "removeInstallation:marine": "Eliminar embarcación",
    "request.draftSaved": "Borrador guardado",
    "request.draftSavingError": "Error al guardar el borrador",
    "request.savingDraft": "Guardando borrador...",
    "request.send": "Enviar solicitud",
    "request.sending": "Enviando solicitud...",
    "request.sendingError": "Error al enviar la solicitud",
    "request.sent": "Solicitud enviada.",
    "routes.contactContractManager": "Póngase en contacto con el Gerente de Contratos",
    "routes.contracts": "Acuerdos",
    "routes.download": "Descargas",
    "routes.equipment": "Producto",
    "routes.fluidReportDraft": "Nuevo reporte de fluidos",
    "routes.installation": "Instalación",
    "routes.installationSelection": "Seleccionar instalación",
    "routes.installationSelection:energy": "Seleccionar planta de energía",
    "routes.installationSelection:marine": "Seleccionar embarcación",
    "routes.newFluidReport": "Nuevo reporte de fluidos",
    "routes.newRequest": "Nueva solicitud",
    "routes.newSFOCReport": "Nuevo reporte de Datos de desempeño SFOC",
    "routes.news": "Noticias",
    "routes.partsCatalogue": "Explorar partes de repuesto",
    "routes.performance": "Desempeño",
    "routes.placeOrder": "Colocar pedido",
    "routes.proceedToOrder": "Continuar a ordenar",
    "routes.reports": "Reportes",
    "routes.requestQuotation": "Solicitar cotización",
    "routes.requests": "Soporte",
    "routes.runningHours": "Horas de operación",
    "routes.spareParts": "Comercio",
    "routes.technicalDocuments": "Documentos técnicos",
    "routes.users": "Usuarios",
    "runningHours": "Horas de operación",
    "runningHours.confirmDialogMessageHigh": "Ingresó valores de horas de operación que son más altos que el tiempo transcurrido desde la última actualización. ¿Desea actualizar el nuevo registro?",
    "runningHours.confirmDialogMessageLow": "Usted ingresó el valor de horas de operación, que es más bajo que el valor actual. Puede realizar una solicitud para actualizar las horas de operación.",
    "runningHours.invalidValue": "Valor no válido",
    "runningHours.lowValue": "Usted ingresó el valor de horas de operación, que es más bajo que el valor actual.",
    "runningHours.makeRequest": "Realizar solicitud",
    "runningHours.newRunningHours": "Nuevas horas de operación",
    "runningHours.reasonForLowerValue": "Razón de menor valor",
    "runningHours.reasonForRequest": "Razón de la solicitud",
    "runningHours.reasonIsRequired": "Se requiere razón",
    "runningHours.runningHoursDateInPast": "La fecha de las horas de operación no puede ser anterior a la última fecha de actualización, por favor elija la fecha correcta antes de continuar.",
    "runningHours.updateRunningHours": "Actualizar horas de operación",
    "runningHours.youCanMakeRequest": "Puede realizar una solicitud para actualizar las horas de operación.",
    "runningHoursUpdated": "Horas de operación actualizadas",
    "save": "Guardar",
    "saveVisibilitySelection": "Guardar",
    "searchInsteadFor": "Buscar en lugar de",
    "searchPlaceholder": "Buscar...",
    "select": "Seleccionar",
    "select.sortPlaceholder": "Ordenar por",
    "selectInstallation": "Seleccionar instalación",
    "selectInstallation:energy": "Seleccionar planta de energía",
    "selectInstallation:marine": "Seleccionar embarcación",
    "send": "Enviar",
    "sendingFileFailed": "Falló",
    "services": "Servicios",
    "showMore": "Mostrar más",
    "showingResultsFor": "Mostrando resultados para",
    "sorting.createdByAZ": "Creado por (A-Z)",
    "sorting.creationDate": "Fecha de creación",
    "sorting.emailShortAZ": "Correo electrónico (A-Z)",
    "sorting.equipmentAZ": "Producto (A-Z)",
    "sorting.installationAZ": "Instalación (A-Z)",
    "sorting.modified": "Modificado",
    "sorting.nameAZ": "Nombre (A-Z)",
    "sorting.newestFirst": "Más reciente primero",
    "sorting.oldestFirst": "Más viejos primero",
    "sorting.orderNumber": "Número de pedido",
    "sorting.poNumber": "Número de pedido",
    "sorting.relevancy": "Más relevante",
    "sorting.requestTypeAZ": "Tipo de solicitud (A-Z)",
    "sorting.statusAZ": "Estado (A-Z)",
    "sorting.subjectAZ": "Asunto (A-Z)",
    "sorting.titleAZ": "Título (A-Z)",
    "sparePartClaim": "Reclamo de parte de repuesto",
    "status": "Estado",
    "techRequest": "Soporte técnico",
    "technicalDocumentation": "Documentos técnicos",
    "type": "Tipo",
    "typeToFilter": "Escriba para filtrar...",
    "unit.cycles": "ciclos",
    "unit.hours": "horas",
    "unit.litres": "litros",
    "unknown": "Desconocido",
    "update": "Actualizar",
    "users": "Usuarios",
    "validation.characterLimit": "Límite de caracteres: {characters}",
    "validation.incorrectPhoneNumber": "El número de teléfono debe comenzar con + y contener de 9 a 14 dígitos",
    "viewContracts": "Ver acuerdos",
    "visibleInstallations": "Instalaciones visibles",
    "visibleInstallations.changeAction": "Realice la solicitud para agregar o eliminar instalación",
    "visibleInstallations.changeAction:energy": "Realice la solicitud para agregar o eliminar planta de energía",
    "visibleInstallations.changeAction:marine": "Realice la solicitud para agregar o eliminar embarcación",
    "visibleInstallations.changeLabel": "¿Se requieren cambios a la lista de instalación?",
    "visibleInstallations.changeLabel:energy": "¿Se requieren cambios a la lista de planta de energía?",
    "visibleInstallations.changeLabel:marine": "¿Se requieren cambios a la lista de embarcación?",
    "visibleInstallations:energy": "Plantas de energía visibles",
    "visibleInstallations:marine": "Embarcaciones visibles",
    "warrantyClaim": "Reclamo de garantía",
    "warrantyEndDate": "Fecha de finalización de garantía",
    "year": "año",
    "years": "años",
    "yes": "Sí",
  },
};
