import * as React from "react";

import { ArrowRightIcon, WarningIcon } from "src/icons";
import { Container, FlexContainer } from "src/design-system/Container";
import { IEquipment, ILineItem, IPartModel } from "online-services-types";
import { fixedFontSizes, fontSizes } from "src/design-system/Tokens/typography";
import { get, merge, set } from "lodash";

import { Dialog } from "../Dialog";
import { DividerColor } from "src/design-system/Divider/Divider";
import { DividerLine } from "src/design-system/Divider";
import { InputField } from "@wartsila/ui-kit";
import { InputLabel } from "src/design-system/TextInput";
import { ModalSize } from "../Dialog/Modal";
import SystemHeader from "../PartsCatalogueList/SystemHeader";
import { breakpoints } from "src/design-system/Tokens/breakpoints";
import colors from "src/design-system/Tokens/colors";
import { spacer } from "src/design-system/Tokens/tokens";
import styled from "styled-components";
import { translateString } from "src/util/localization";
import { validators } from "src/util/validators";

type Props = {
  opened: boolean;
  onCancel: () => void;
  onError?: () => void;
  onSuccess?: () => void;
  equipments: IEquipment[];
  focusOnRow: IPartModel | null;
  items: Record<string, ILineItem[]>;
  uploadButton: (part: ILineItem) => React.ReactNode;
  onSubmit: (parts: IPartModel | IPartModel[]) => void;
  isWarranty?: boolean;
};

const Table = styled(Container)`
  width: 80vw;
`;

const Row = styled(FlexContainer)`
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;
  gap: 1rem;
`;

const Column = styled(FlexContainer)`
  display: flex;
  flex-basis: 100%;
  flex-direction: column;

  @media screen and (min-width: ${breakpoints.tablet}px) {
    flex: 1;
  }
`;

const Arrow = styled(ArrowRightIcon)<{ $down: boolean }>`
  margin-top: 4px;
  transform: rotate(${(props) => (props.$down ? "-90deg" : "180deg")});
`;

const ArrowContainer = styled(FlexContainer)`
  cursor: pointer;
  align-self: start;
`;

const LineNumberContainer = styled(Container)`
  width: 80px;
`;

const LineNumberLabel = styled.div`
  font-size: ${fixedFontSizes.baseFontSize}px;
`;

const InputError = styled.div`
  min-height: 1rem;
  padding: ${spacer(1)} 0;
  font-size: ${fontSizes.xs.min}px;
  color: ${colors.notification.error};
`;

const TopError = styled(FlexContainer)`
  min-height: 20px;
`;

export const EditItemsDialog = (props: Props) => {
  const [opened, setOpened] = React.useState<Record<string, boolean>>({});
  const [row, setRow] = React.useState<Record<string, ILineItem[]>>({});
  const [updates, setUpdates] = React.useState<Record<string, ILineItem[]>>({});
  const [errors, setErrors] = React.useState<Record<string, Record<string, string>[]>>({});

  React.useEffect(() => {
    if (props.opened) {
      setRow(props.items);
    }
  }, [props.opened, JSON.stringify(props.items)]);

  const items = merge(row, updates);

  React.useEffect(() => {
    const errors = {};
    Object.keys(items).forEach((equipmentId) => {
      const parts = items[equipmentId];
      parts.forEach((part, partIdx) => {
        const idPath = `${equipmentId}.${partIdx}.id`;
        const materialTextPath = `${equipmentId}.${partIdx}.materialText`;

        if (!validators.isStringLengthMax(18)(part.id)) {
          set(errors, idPath, translateString("validation.characterLimit", { characters: 18 }));
        } else {
          set(errors, idPath, "");
        }

        let maxLength = props.isWarranty ? 40 : 255;
        if (!validators.isStringLengthMax(maxLength)(part.materialText)) {
          set(errors, materialTextPath, translateString("validation.characterLimit", { characters: maxLength }));
        } else if (!part.materialText.trim()) {
          set(errors, materialTextPath, translateString("spareParts.partDescriptionIsRequired"));
        } else {
          set(errors, materialTextPath, "");
        }
      });
    });

    setErrors(errors);
  }, [JSON.stringify(items)]);

  React.useEffect(() => {
    if (props.focusOnRow) {
      setOpened({});
      const equipmentId = props.focusOnRow.equipmentId;
      if (!items[equipmentId]) return;

      const partIdx = items[equipmentId].findIndex((part) => part.frontendKey === props.focusOnRow!.frontendKey);
      const equipmentIdWithPartLineItem = `${equipmentId}.${items[equipmentId][partIdx].lineNumber}`;
      setOpened((state) => ({
        ...state,
        [equipmentIdWithPartLineItem]: true,
      }));
    }
  }, [props.focusOnRow, JSON.stringify(items)]);

  const onChange = ({
    event,
    partIdx,
    equipmentId,
  }: {
    partIdx: number;
    equipmentId: string;
    event: React.ChangeEvent<HTMLInputElement>;
  }) => {
    const { name, value } = event.target;
    setUpdates((state) => {
      const stateCopy = merge({}, state);
      set(stateCopy, `${equipmentId}.${partIdx}.${name}`, value);
      return stateCopy;
    });
  };

  const hasErrors = Object.values(errors)
    .flat()
    .some((error) => Boolean(Object.values(error).find(Boolean)));

  return (
    <Dialog
      size={ModalSize.Large}
      submit={{
        buttonDisabled: hasErrors,
        callback: () => {
          return props.onSubmit(Object.values(items).flat());
        },
        buttonTitle: translateString("spareParts.saveInformation"),
      }}
      onCancel={() => {
        setUpdates({});
        return props.onCancel();
      }}
      isDialogOpen={props.opened}
      title={translateString("spareParts.editInformation")}
    >
      <>
        <Container $marginBottom={3}>
          <DividerLine $color={DividerColor.Gray} />
        </Container>

        <TopError $gap={1} $centered>
          {hasErrors ? (
            <>
              <WarningIcon color={colors.primary.orange} />
              {translateString("spareParts.fillRequiredFields")}
            </>
          ) : null}
        </TopError>
        {Object.keys(items).map((equipmentId) => {
          const title = getEquipmentName(equipmentId, props.equipments) || "";
          const parts = items[equipmentId];

          return (
            <Table $margin={[3, 0]}>
              <SystemHeader title={title} />
              {parts.map((part, partIdx) => {
                const hasErrors = Object.keys(errors).length
                  ? Object.values(get(errors, `${equipmentId}.${partIdx}`)).some(Boolean)
                  : false;

                const equipmentIdWithPartLineItem = `${equipmentId}.${part.lineNumber}`;
                return (
                  <Container>
                    <FlexContainer $margin={[5, 0]}>
                      <FlexContainer $gap={3}>
                        <ArrowContainer
                          $marginTop={4}
                          onClick={() =>
                            setOpened((state) => ({
                              ...state,
                              [equipmentIdWithPartLineItem]: !state[equipmentIdWithPartLineItem],
                            }))
                          }
                        >
                          <Arrow $down={get(opened, equipmentIdWithPartLineItem)} color={colors.primary.blue} />
                        </ArrowContainer>
                        <LineNumberContainer $marginRight={8}>
                          <LineNumberLabel>{translateString("spareParts.lineNumber")}</LineNumberLabel>
                          <FlexContainer $gap={2} $marginTop={2}>
                            {part.lineNumber}
                            {hasErrors ? <WarningIcon color={colors.primary.orange} /> : null}
                          </FlexContainer>
                        </LineNumberContainer>
                      </FlexContainer>
                      <Container $fullwidth>
                        <FlexContainer $wrap>
                          <Row>
                            <Column>
                              {(() => {
                                const error = get(errors, `${equipmentId}[${partIdx}].id`);
                                return (
                                  <>
                                    <InputLabel>{translateString("spareParts.partNumber")}</InputLabel>
                                    <InputField
                                      crossOrigin=""
                                      name="id"
                                      autoFocus={Boolean(props.focusOnRow?.frontendKey === part.frontendKey)}
                                      invalid={Boolean(error)}
                                      value={part.id}
                                      onChange={(event) => {
                                        onChange({
                                          event,
                                          partIdx,
                                          equipmentId,
                                        });
                                      }}
                                    />
                                    <InputError>
                                      <>{error}</>
                                    </InputError>
                                  </>
                                );
                              })()}
                            </Column>
                            <Column>
                              {(() => {
                                const error = get(errors, `${equipmentId}[${partIdx}].materialText`);
                                return (
                                  <>
                                    <InputLabel>{translateString("spareParts.partDescription")}</InputLabel>
                                    <InputField
                                      crossOrigin=""
                                      invalid={Boolean(error)}
                                      name="materialText"
                                      onClick={() => {
                                        if (!part.materialText) {
                                          setOpened((state) => ({
                                            ...state,
                                            [equipmentIdWithPartLineItem]: true,
                                          }));
                                        }
                                      }}
                                      value={part.materialText}
                                      onChange={(event) => {
                                        onChange({
                                          event,
                                          partIdx,
                                          equipmentId,
                                        });
                                      }}
                                    />
                                    <InputError>
                                      <>{error}</>
                                    </InputError>
                                  </>
                                );
                              })()}
                            </Column>
                            <Column>
                              {(() => {
                                const error = get(errors, `${equipmentId}[${partIdx}].serialNumber`);
                                return (
                                  <>
                                    <InputLabel>{translateString("spareParts.serialNumber")}</InputLabel>
                                    <InputField
                                      crossOrigin=""
                                      name="serialNumber"
                                      invalid={Boolean(error)}
                                      value={part.serialNumber}
                                      onChange={(event) => {
                                        onChange({
                                          event,
                                          partIdx,
                                          equipmentId,
                                        });
                                      }}
                                    />
                                    <InputError>
                                      <>{error}</>
                                    </InputError>
                                  </>
                                );
                              })()}
                            </Column>
                          </Row>

                          {get(opened, equipmentIdWithPartLineItem, false) ? (
                            <>
                              <Row>
                                <Column>
                                  {(() => {
                                    const error = get(errors, `${equipmentId}[${partIdx}].vendorCode`);
                                    return (
                                      <>
                                        <InputLabel>{translateString("spareParts.vendorCode")}</InputLabel>
                                        <InputField
                                          crossOrigin=""
                                          name="vendorCode"
                                          value={part.vendorCode}
                                          invalid={Boolean(error)}
                                          onChange={(event) => {
                                            onChange({
                                              event,
                                              partIdx,
                                              equipmentId,
                                            });
                                          }}
                                        />
                                        <InputError>
                                          <>{error}</>
                                        </InputError>
                                      </>
                                    );
                                  })()}
                                </Column>
                                <Column>
                                  {(() => {
                                    const error = get(errors, `${equipmentId}[${partIdx}].markings`);
                                    return (
                                      <>
                                        <InputLabel>{translateString("spareParts.markings")}</InputLabel>
                                        <InputField
                                          crossOrigin=""
                                          name="markings"
                                          value={part.markings}
                                          invalid={Boolean(error)}
                                          onChange={(event) => {
                                            onChange({
                                              event,
                                              partIdx,
                                              equipmentId,
                                            });
                                          }}
                                        />
                                        <InputError>
                                          <>{error}</>
                                        </InputError>
                                      </>
                                    );
                                  })()}
                                </Column>
                                <Column>
                                  {(() => {
                                    const error = get(errors, `${equipmentId}[${partIdx}].sizeAndDimensions`);
                                    return (
                                      <>
                                        <InputLabel>{translateString("spareParts.sizeAndDimensions")}</InputLabel>
                                        <InputField
                                          crossOrigin=""
                                          name="sizeAndDimensions"
                                          invalid={Boolean(error)}
                                          value={part.sizeAndDimensions}
                                          onChange={(event) => {
                                            onChange({
                                              event,
                                              partIdx,
                                              equipmentId,
                                            });
                                          }}
                                        />
                                        <InputError>
                                          <>{error}</>
                                        </InputError>
                                      </>
                                    );
                                  })()}
                                </Column>
                              </Row>
                              <Row>
                                <Column>
                                  {(() => {
                                    const error = get(errors, `${equipmentId}[${partIdx}].usageTarget`);
                                    return (
                                      <>
                                        <InputLabel>{translateString("spareParts.usageTarget")}</InputLabel>
                                        <InputField
                                          crossOrigin=""
                                          name="usageTarget"
                                          invalid={Boolean(error)}
                                          value={part.usageTarget}
                                          onChange={(event) => {
                                            onChange({
                                              event,
                                              partIdx,
                                              equipmentId,
                                            });
                                          }}
                                        />
                                        <InputError>
                                          <>{error}</>
                                        </InputError>
                                      </>
                                    );
                                  })()}
                                </Column>
                                <Column />
                                <Column />
                              </Row>
                            </>
                          ) : null}
                        </FlexContainer>

                        {get(opened, equipmentIdWithPartLineItem, false) ? props.uploadButton(part) : null}
                      </Container>
                    </FlexContainer>
                    <DividerLine $color={DividerColor.Gray} />
                  </Container>
                );
              })}
            </Table>
          );
        })}
      </>
    </Dialog>
  );
};

const getEquipmentName = (equipmentId: string, equipments: IEquipment[]) => {
  const equipment = equipments.find((eq) => eq.id === equipmentId);
  if (equipment) {
    return equipment.nickName ? equipment.nickName : equipment.description;
  } else {
    return `${translateString("spareParts.productId")} ${equipmentId}`;
  }
};
