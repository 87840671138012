import * as React from "react";

import { Button, ButtonStyle } from "src/design-system/Button";
import { CloseIcon, IconSize } from "src/icons";
import { H1, H2 } from "src/design-system/Tokens/typography";
import { Modal, ModalSize } from "./Modal";

import { breakpoints } from "src/design-system/Tokens/breakpoints";
import colors from "src/design-system/Tokens/colors";
import { spacer } from "src/design-system/Tokens/tokens";
import styled from "styled-components";
import { translateString } from "src/util/localization";

const HeaderWrapper = styled.div`
  display: flex;
  width: 100%;
  padding-bottom: 12px;

  & div {
    cursor: pointer;
    margin-left: auto;
  }
`;

export const Heading = styled(H1)`
  color: ${colors.primary.black};

  margin-right: auto;
  padding-right: ${spacer(3)};

  @media (max-width: ${breakpoints.mobileLarge}px) {
    max-width: calc(100% - 50px);
  }
`;

export const Subheading = styled(H2)`
  color: ${colors.primary.black};

  margin-right: auto;
  padding-right: ${spacer(10)};

  text-transform: none;

  font-size: 1rem;
  font-weight: 600;

  @media (max-width: ${breakpoints.mobileLarge}px) {
    max-width: calc(100% - 50px);
  }
`;

export const ModalWrapper = styled.div`
  display: block;
  height: 100%;
`;

interface IContentWrapperProps {
  showTopShadow?: boolean;
  showBottomShadow?: boolean;
  noOverflow?: boolean;
  overflowType?: string;
}

const overallShadow = `box-shadow: inset 0 40px 40px -40px ${colors.transparent.black},
  inset 0 -40px 40px -40px ${colors.transparent.black};`;
const topShadow = `box-shadow: inset 0 40px 40px -40px ${colors.transparent.black};`;
const bottomShadow = `box-shadow: inset 0 -40px 40px -40px ${colors.transparent.black};`;

export const ContentWrapper = styled.div<IContentWrapperProps>`
  height: calc(100% - 150px);
  width: 100%;
  padding: 0 1px; // For UI elements focus border visibility
  box-shadow: inset 0 0 10px -10px ${colors.transparent.black};

  ${(props: IContentWrapperProps) => {
    if (props.overflowType) {
      return "overflow: " + props.overflowType + ";";
    }
    return props.noOverflow ? "" : "overflow: auto;";
  }};

  ${(props: IContentWrapperProps) => {
    if (props.showBottomShadow && props.showTopShadow) {
      return overallShadow;
    }

    if (props.showBottomShadow) {
      return bottomShadow;
    }

    if (props.showTopShadow) {
      return topShadow;
    }

    return "";
  }}
`;

export const ActionsWrapper = styled.div`
  display: flex;
  justify-content: space-around;
  width: 100%;
  margin-top: auto;
  padding: 20px 0;
`;

export const CloseIconWrapper = styled.span`
  cursor: pointer;
`;

export interface IDialogState {
  modalOpen: boolean;
  showTopShadow: boolean;
  showBottomShadow: boolean;
}

interface IDialogHeader {
  title?: string;
  subtitle?: string;
  onCancel(): void;
}

export const DialogHeader = ({ title, subtitle, onCancel }: IDialogHeader) => (
  <HeaderWrapper>
    {title ? <Heading>{title}</Heading> : null}
    {subtitle ? <Subheading>{subtitle}</Subheading> : null}
    <CloseIconWrapper onClick={onCancel}>
      <CloseIcon size={IconSize.Small} color={colors.secondary.blue100} />
    </CloseIconWrapper>
  </HeaderWrapper>
);

/* --------- example of using Dialog -------------

  -If submit object is not specified, then the 'Submit' button wont be visible and
  there will be only the cancel button which will have text 'Close'

  -If submit object is specified then there will be two buttons, 'Cancel' and submit button with provided text and callback

  <Dialog
    title="Some title"
    isDialogOpen={true}
    submit={
      buttonTitle: 'Yes',
      callback: () => {}
    }
    onCancel={() => {}}
  >
    <div>hello, do you want to submit?</div>
  </Dialog>

*/

type ButtonProps = {
  buttonDisabled?: boolean;
  buttonTitle: string;
  callback(): void;
  buttonStyle?: ButtonStyle;
};

export interface IDialogProps {
  title?: string;
  subtitle?: string;
  isDialogOpen: boolean;
  children: React.ReactNode;
  size?: ModalSize;
  submit?: ButtonProps;
  extraButtons?: ButtonProps[];
  cancelButtonTitle?: string;
  hideCancelButton?: boolean;
  overflowType?: string;
  onCancel(): void;
  onAfterOpen?(): void;
}

export const Dialog = ({
  onAfterOpen,
  isDialogOpen,
  size,
  title,
  subtitle,
  submit,
  extraButtons,
  onCancel,
  cancelButtonTitle,
  overflowType,
  children,
  hideCancelButton = false,
}: IDialogProps) => (
  <Modal onAfterOpen={onAfterOpen} isOpen={isDialogOpen} size={size} overflowType={overflowType}>
    <ModalWrapper>
      <DialogHeader subtitle={subtitle} title={title} onCancel={onCancel} />

      <ContentWrapper>{children}</ContentWrapper>

      <ActionsWrapper>
        {!hideCancelButton && (
          <Button buttonStyle={ButtonStyle.Secondary} onClick={onCancel}>
            {cancelButtonTitle || (submit ? translateString("button.cancel") : translateString("button.close"))}
          </Button>
        )}
        {extraButtons?.map((button, index) => (
          <Button key={index} disabled={button.buttonDisabled} buttonStyle={button.buttonStyle} onClick={button.callback}>
            {button.buttonTitle}
          </Button>
        ))}
        {submit && (
          <Button disabled={submit.buttonDisabled} buttonStyle={submit.buttonStyle} onClick={submit.callback}>
            {submit.buttonTitle}
          </Button>
        )}
      </ActionsWrapper>
    </ModalWrapper>
  </Modal>
);
