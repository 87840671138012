import * as React from "react";

import {
  BasketIcon,
  ChartIcon,
  ContractIcon,
  ExpertInsightIcon,
  IconSize,
  InformationIcon,
  ManagerDashboardIcon,
  NewsIcon,
  PerformanceIcon,
  RequestsIcon,
  RunningHoursIcon,
  TechnicalDocumentation,
  TrainingIcon,
} from "src/icons";
import { Grid, GridRowSmallItems } from "src/design-system/Tokens/grid";
import { IEquipment, INewsItem, INotification, Service } from "online-services-types";
import { IRoutes, getPathWithParams } from "src/routes";
import {
  filterCMReportNotifications,
  filterDocumentNotifications,
  filterQuotationNotifications,
  filterRequestNotifications,
  removeDiscardedNotifications,
} from "src/models/notifications";

import { NavigationButton } from "src/design-system/NavigationButton";
import { filterUnreadNews } from "src/views/NewsView/news";
import { isRunningInProduction } from "src/redux/applicationContext";
import moment from "moment";
import { translateString } from "src/util/localization";

interface IServicesGridProps {
  routes: IRoutes;
  services: ReadonlyArray<Service>;
  selectedInstallationId: string | undefined;
  notifications: INotification[];
  news: INewsItem[];
  showManagerActions?: boolean;
  isShipyard?: boolean;
  displayExpertInsightLink: boolean;
  equipments: IEquipment[];
}

const expertInsightUrl = isRunningInProduction()
  ? "https://expertinsight.wartsila.com/?from=wartsila-online"
  : "https://expertinsight-acc.dpd.wartsila.io/?from=wartsila-online";

export class ServicesGrid extends React.Component<IServicesGridProps> {
  public render() {
    const {
      routes,
      services,
      selectedInstallationId,
      notifications,
      news,
      showManagerActions = false,
      isShipyard,
      equipments,
      displayExpertInsightLink,
    } = this.props;

    const hasRunningHoursEngines = equipments.filter((equipment) => equipment.isRunningHoursEngine).length > 0;
    const requiresRunningHoursUpdate = Boolean(
      equipments.find((equipment) => moment().diff(equipment.runningHoursDate, "days") > 365)
    );

    return (
      <Grid itemsPerRow={GridRowSmallItems} fallbackWidth={{ min: 125, max: 140 }}>
        {isShipyard && (
          <NavigationButton
            href={{
              pathname: getPathWithParams(routes.InstallationInformation),
              state: { installationId: selectedInstallationId },
            }}
            icon={<InformationIcon size={IconSize.Large} />}
            label={translateString("routes.installationInformation")}
          />
        )}
        {!isShipyard && (
          <>
            {routes.SpareParts.isEnabled(services) && (
              <NavigationButton
                href={{
                  pathname: getPathWithParams(routes.SpareParts),
                  state: { installationId: selectedInstallationId },
                }}
                icon={<BasketIcon size={IconSize.Large} />}
                label={translateString("spareParts.serviceGridButtonTitle")}
                notificationCount={filterQuotationNotifications(notifications).length}
              />
            )}
          </>
        )}
        {routes.Documents.isEnabled(services) && (
          <NavigationButton
            href={getPathWithParams(routes.Documents)}
            icon={<TechnicalDocumentation size={IconSize.Large} />}
            label={translateString("routes.technicalDocuments")}
            notificationCount={removeDiscardedNotifications(filterDocumentNotifications(notifications)).length}
          />
        )}
        {routes.Requests.isEnabled(services) && (
          <NavigationButton
            href={`${getPathWithParams(routes.Requests)}${
              selectedInstallationId ? "?installationId=" + selectedInstallationId : ""
            }`}
            icon={<RequestsIcon size={IconSize.Large} />}
            label={translateString("routes.requests")}
            notificationCount={filterRequestNotifications(notifications).length}
          />
        )}
        {!isShipyard && (
          <>
            {routes.RunningHours.isEnabled(
              services,
              undefined,
              undefined,
              undefined,
              undefined,
              hasRunningHoursEngines
            ) && (
              <NavigationButton
                href={getPathWithParams(
                  routes.RunningHours,
                  selectedInstallationId ? { installationId: selectedInstallationId } : undefined
                )}
                label={translateString("routes.runningHours")}
                icon={<RunningHoursIcon size={IconSize.Large} />}
                notificationCount={requiresRunningHoursUpdate ? "!" : ""}
                notificationMessage={translateString("runningHours.updateHoursMessage")}
              />
            )}
            {
              <NavigationButton
                href={getPathWithParams(routes.News)}
                icon={<NewsIcon size={IconSize.Large} />}
                label={translateString("routes.news")}
                notificationCount={filterUnreadNews(news, selectedInstallationId).length}
              />
            }
            {routes.Trainings.isEnabled(services) && (
              <NavigationButton
                href={getPathWithParams(
                  routes.Trainings,
                  selectedInstallationId ? { installationId: selectedInstallationId } : undefined
                )}
                icon={<TrainingIcon size={IconSize.Large} />}
                label={translateString("routes.trainings")}
              />
            )}
            {routes.Contract.isEnabled(services) && (
              <NavigationButton
                href={getPathWithParams(routes.Contract)}
                icon={<ContractIcon size={IconSize.Large} />}
                label={translateString("routes.contracts")}
              />
            )}
            {routes.Reports.isEnabled(services) && (
              <NavigationButton
                href={getPathWithParams(routes.Reports)}
                icon={<ChartIcon size={IconSize.Large} />}
                label={translateString("routes.reports")}
                notificationCount={filterCMReportNotifications(notifications).length}
              />
            )}
            {displayExpertInsightLink && (
              <NavigationButton
                externalURL={expertInsightUrl}
                icon={<ExpertInsightIcon size={IconSize.Large} />}
                label={translateString("routes.expertInsight")}
              />
            )}
            {routes.Charts.isEnabled(services) && (
              <NavigationButton
                href={getPathWithParams(routes.Charts)}
                icon={<PerformanceIcon size={IconSize.Large} />}
                label={translateString("routes.performance")}
              />
            )}
            {routes.ManagerDashboard.isEnabled(services, showManagerActions) && !this.props.selectedInstallationId && (
              <NavigationButton
                href={getPathWithParams(routes.ManagerDashboard)}
                icon={<ManagerDashboardIcon size={IconSize.Large} />}
                label={translateString("routes.managerDashboard")}
              />
            )}
            {routes.SFOCVesselDashboard.isEnabled(services, undefined, undefined, undefined) &&
              !this.props.selectedInstallationId && (
                <NavigationButton
                  href={getPathWithParams(routes.SFOCVesselDashboard)}
                  icon={<ManagerDashboardIcon size={IconSize.Large} />}
                  label={translateString("routes.SFOCDashboard")}
                />
              )}
          </>
        )}
      </Grid>
    );
  }
}
