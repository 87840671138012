import * as React from "react";
import ReactModal from "react-modal";
import styled, { ThemeProvider } from "styled-components";

import colors from "src/design-system/Tokens/colors";
import { breakpoints } from "src/design-system/Tokens/breakpoints";
import { themes } from "src/design-system/Theme/theme";

interface IReactModalAdapter {
  className?: string;
  isOpen: boolean;
  children?: React.ReactNode;
  overflowType?: string;
}

const ReactModalAdapter = ({ className, ...props }: IReactModalAdapter) => (
  <ThemeProvider theme={themes.light}>
    <ReactModal
      portalClassName={className}
      className={`${className}__content`}
      overlayClassName={`${className}__overlay`}
      {...props}
    />
  </ThemeProvider>
);

export enum ModalSize {
  Large,
  Small,
}

interface IModal {
  isOpen: boolean;
  size?: ModalSize;
  onAfterOpen?(): void;
  isTransparentContentBackground?: boolean;
  width?: string;
  height?: string;
}

export const Modal = styled(ReactModalAdapter)<IModal>`
  &__overlay {
    position: fixed;
    top: 0px;
    left: 0px;
    right: 0px;
    bottom: 0px;
    background-color: ${colors.transparent.black};
    z-index: 1500;
  }

  &__content {
    position: absolute;
    top: 4px;
    left: 4px;
    right: 4px;
    bottom: 4px;
    border: ${(props) => (!props.isTransparentContentBackground ? "1px solid #ccc" : "none")};
    background: ${(props) => (!props.isTransparentContentBackground ? "#fff" : "transparent")};
    overflow: ${(props) => (props.overflowType ? props.overflowType : "auto")};
    -webkit-overflow-scrolling: touch;
    outline: none;
    padding: 30px 15px 20px;
  }

  @media (min-width: ${breakpoints.tablet}px) {
    &__content {
      top: 50%;
      left: 50%;
      right: auto;
      transform: translate(-50%, -50%);
      bottom: auto;
      padding: 30px 40px 10px;
      max-height: calc(100% - 150px);
      ${({ width }) => width ? `width: ${width};` : ""}
      ${({ height }) => height ? `height: ${height};` : ""}
    }
  }
`;
