// Wärtsilä Online translations exported from Salesforce. Please see "Translations" in Confluence for correct workflow.
import { ITranslations } from "../../localization";

// tslint:disable-next-line: variable-name
export const main_ja: ITranslations = {
  ja: {
    "account.accountSelected": "選択したアカウント",
    "account.selectingAccount": "アカウントを選択しています...",
    "addAttachment": "添付ファイルを追加する",
    "addFilter": "フィルターを追加する",
    "addInstallation": "設備を追加する",
    "addInstallation.nameIsRequired": "名称が必要です",
    "addInstallation:energy": "発電プラントを追加する",
    "addInstallation:marine": "船舶を追加する",
    "applyFilter": "フィルターを適用する",
    "attachments": "添付書類",
    "auth.continueToUseOS": "Wärtsilä Onlineの使用を継続する",
    "auth.logout": "ログアウト",
    "auth.readOnlyMode": "読み取り専用モード",
    "auth.redirectingToLogin": "ログインにリダイレクト中...",
    "auth.sessionExpired": "セッションのタイムアウト制限に達しました。ログインページにリダイレクトされます",
    "auth.sessionWillExpire": "セッションはまもなく期限切れになります",
    "button.cancel": "キャンセル",
    "button.close": "Close (閉じる)",
    "button.next": "次へ",
    "button.previous": "前へ",
    "button.saveForLater": "保存して後で使用する",
    "button.send": "送信する",
    "button.skip": "スキップする",
    "button.updateDraft": "下書きを更新する",
    "cancel": "キャンセル",
    "change": "変更",
    "changePasswordDialog.success": "説明が記載された電子メールが無事に送信されました。",
    "chooseDocumentType": "文書をフィルター処理する",
    "chooseFilter": "フィルターを選択する…",
    "clearFilters": "フィルターを削除する",
    "comment.commentIsRequired": "コメントが必要です",
    "comment.postComment": "コメントを投稿する",
    "comment.replyComment": "返信する",
    "comment.writeYourComment": "コメントを書く",
    "comment.writeYourReply": "返信を書く",
    "comment.you": "あなた",
    "commenting": "コメントする",
    "contact": "連絡先",
    "contact.contactWartsilaCustomerSupport": "Wärtsiläカスタマーサポートへご連絡ください",
    "contact.subject": "件名",
    "contact.subjectIsRequired": "件名が必要です",
    "contact.yourMessage": "あなたのメッセージ",
    "contact.yourMessageIsRequired": "メッセージが必要です",
    "contactCustomerSupport": "カスタマーサポートに連絡する",
    "contextHelp": "クイックツアー",
    "customerSupportTicket": "カスタマーサポートチケット",
    "date": "日付",
    "delete": "削除",
    "didYouMean": "もしかして:",
    "dismissAll": "すべて却下する",
    "download.downloadReady": "ダウンロードの準備が完了しました!",
    "download.equipmentNotFound": "製品の記載が見つかりませんでした",
    "download.error": "ダウンロードにエラーがありました",
    "download.preparingDownload": "ダウンロードの準備",
    "draft.confirmDelete": "本当に下書きを削除してもよいですか?",
    "draft.confirmDeleteTitle": "下書きを削除しますか?",
    "draft.continueEditing": "編集を続行する",
    "draft.delete": "下書きを削除する",
    "edit": "編集",
    "engine": "エンジン",
    "equipment": "製品",
    "equipment.configuration": "設定",
    "equipment.nickname": "製品のニックネーム",
    "equipment.performanceData": "性能",
    "equipment.title": "タイトル",
    "equipment.validation.nicknameTooLong": "ニックネームの長さは最大{maxLength}です",
    "equipmentNotFound": "製品はありません",
    "error.networkError": "ネットワークエラー",
    "error.networkErrorPleaseCheckConnectivity": "接続を確認してください。",
    "error.readOnlyErrorMsg": "読み取り専用モードです。",
    "error.readOnlyErrorTitle": "読み取り専用エラー",
    "fileUpload.attachmentRejected": "添付ファイルは却下されました。アップロードの制限を確認してください。",
    "fileUpload.noContactPerson": "担当者名がありません。メッセージを送信できません!",
    "fileUpload.spareParts.limit": "最大アップロードファイルサイズ: 5MB",
    "fileUpload.uploadAborted": "ファイルのアップロードが中止されました",
    "fileUpload.uploadFailed": "ファイルのアップロードに失敗しました",
    "fileUpload.userHasReadOnlyAccess": "ユーザーは読み取り専用アクセス権を有しています",
    "filterRequests": "リクエストをフィルター処理する",
    "iDontKnow": "分かりません",
    "index.installationDetails": "設備の詳細",
    "installation": "設備",
    "installation.classificationSociety": "船級協会",
    "installation.deliveryDate": "配送日",
    "installation.description": "部品名称",
    "installation.imo": "IMO",
    "installation.inspectionDate": "点検日",
    "installation.name": "名称",
    "installation.nextPlannedMaintenance": "次回予定のメンテナンス",
    "installation.type": "タイプ",
    "installation:energy": "発電プラント",
    "installation:marine": "船舶",
    "installations": "設備",
    "installations:energy": "発電プラント",
    "installations:marine": "船舶",
    "language": "言語",
    "language.languageNotFound": "言語コードで言語が見つかりませんでした",
    "languageDisclaimer": "このリクエストを英語で送信すると、処理プロセスが速くなります",
    "letsStart": "始めましょう",
    "loading": "ロード中...",
    "logout": "Logout (ログアウト)",
    "main.loadMore": "さらにロード",
    "main.pageNotFound": "下記URLは見つかりませんでした: {url}",
    "main.pageNotFoundTitle": "見つかりませんでした",
    "manufacturerSerialNumber": "メーカーのシリアル番号",
    "month.april": "4月",
    "month.august": "8月",
    "month.december": "12月",
    "month.february": "2月",
    "month.january": "1月",
    "month.july": "7月",
    "month.june": "6月",
    "month.march": "3月",
    "month.may": "5月",
    "month.november": "11月",
    "month.october": "10月",
    "month.september": "9月",
    "months": "月",
    "myFleet": "私の設備",
    "myFleet:marine": "私のフリート",
    "myInstallations": "私の設備",
    "myInstallations:energy": "私の発電プラント",
    "myInstallations:marine": "私の船舶",
    "myProfile": "私のプロファイル",
    "news.furtherQuestions": "追加のコメントまたは質問",
    "news.productsAreRequired": "製品が必要です",
    "news.quotationRequestSent": "見積リクエストが送信されました。",
    "news.readMore": "さらに読む",
    "news.requestQuotation": "見積をリクエストする",
    "news.selectTheProductsForTheQuotation": "見積のための製品を選択します",
    "news.sendingQuotationRequest": "見積リクエストを送信しています。",
    "news.youAreAskingQuotationFor": "{productName}の見積を依頼しています。",
    "nickname": "ニックネーム",
    "no": "いいえ",
    "noDocumentsFound": "ドキュメントは見つかりませんでした",
    "noVisibleInstallationsFound": "表示可能な{sector}を定義していません",
    "noVisibleInstallationsFoundHelp": "これは{sector}から行うことができます",
    "notApplicable": "適用できません",
    "notificationList.markedNotificationsAsRead": "{count}件の通知を既読としてマークしました",
    "notificationList.title.criticalBulletinUpdated": "即時対応に関する速報: {title}",
    "notificationList.title.equipmentRunningHoursUpdate": "稼働時間を更新する必要があります",
    "notificationList.title.manualUpdated": "マニュアルのチャプターが更新されました: {title}",
    "notificationList.title.newAttachment": "新しい添付ファイル",
    "notificationList.title.newCMReport": "新しいレポート",
    "notificationList.title.newComment": "新しいコメント",
    "notificationList.title.newRequest": "新しいリクエストが作成されました",
    "notificationList.title.newSalesOrder": "新しい受注",
    "notificationList.title.newServiceQuotation": "新しいサービス見積",
    "notificationList.title.newSparePartClaim": "新しい予備部品請求",
    "notificationList.title.resolvedSparePartClaim": "解決した予備部品請求",
    "notificationList.title.salesOrderConfirmed": "受注が確認されました",
    "notificationList.title.serviceQuotationQuoted": "サービス見積が完了しました",
    "notificationList.title.techRequestSolutionPlan": "新しいソリューション計画が追加されました",
    "notificationList.title.waitingForRating": "評価を待っています",
    "notificationList.title.waitingForReply": "回答を待っています",
    "notificationList.title.warrantyContactUpdated": "保証に関する連絡先が更新されました",
    "notifications.lastWeek": "先週",
    "notifications.markAllRead": "すべて既読にする",
    "notifications.older": "さらに古い",
    "notifications.thisWeek": "今週",
    "notifications.title": "通知 ({unreadNotifications})",
    "notifications.today": "今日",
    "notifications.yesterday": "昨日",
    "ok": "OK",
    "optionalFieldTitle": "オプション",
    "orderBeingProcessed": "処理中の注文",
    "phone": "電話",
    "pleaseAddInstallation": "設備を選択してください!",
    "pleaseAddInstallation:energy": "発電プラントを選択してください!",
    "pleaseAddInstallation:marine": "船舶を選択してください!",
    "productNumber": "製品番号",
    "productType": "製品タイプ",
    "profile.updating": "プロファイルを更新中…",
    "quotationBeingProcessed": "処理中の見積",
    "removeInstallation": "設備を削除する",
    "removeInstallation:energy": "発電プラントを削除する",
    "removeInstallation:marine": "船舶を削除する",
    "request.draftSaved": "下書きを保存しました",
    "request.draftSavingError": "下書きを保存中にエラーが発生しました",
    "request.savingDraft": "下書きを保存中...",
    "request.send": "リクエストを送信する",
    "request.sending": "リクエストを送信中...",
    "request.sendingError": "リクエストを送信中にエラーが発生しました",
    "request.sent": "リクエストを送信しました。",
    "routes.contactContractManager": "契約マネージャーに連絡する",
    "routes.contracts": "契約",
    "routes.download": "ダウンロード",
    "routes.equipment": "製品",
    "routes.fluidReportDraft": "新しいフルードレポート",
    "routes.installation": "取り付け",
    "routes.installationSelection": "設備を選択する",
    "routes.installationSelection:energy": "発電プラントを選択する",
    "routes.installationSelection:marine": "船舶を選択する",
    "routes.newFluidReport": "新しいフルードレポート",
    "routes.newRequest": "新しいリクエスト",
    "routes.newSFOCReport": "新しいSFOC性能データレポート",
    "routes.news": "ニュース",
    "routes.partsCatalogue": "予備部品を閲覧する",
    "routes.performance": "性能",
    "routes.placeOrder": "注文する",
    "routes.proceedToOrder": "注文に進む",
    "routes.reports": "レポート",
    "routes.requestQuotation": "見積を依頼する",
    "routes.requests": "サポート",
    "routes.runningHours": "稼働時間",
    "routes.spareParts": "コマース",
    "routes.technicalDocuments": "技術文書",
    "routes.users": "ユーザー",
    "runningHours": "稼働時間",
    "runningHours.confirmDialogMessageHigh": "入力した稼働時間の値が、最後の更新から経過した時間よりも長いです。本当に新しい記録を更新しますか?",
    "runningHours.confirmDialogMessageLow": "入力した稼働時間の値が、現在の値よりも低いです。稼働時間の更新をリクエストできます。",
    "runningHours.invalidValue": "無効な値",
    "runningHours.lowValue": "入力した稼働時間の値が、現在の値よりも低いです。",
    "runningHours.makeRequest": "リクエストする",
    "runningHours.newRunningHours": "新しい稼働時間",
    "runningHours.reasonForLowerValue": "値が低い理由",
    "runningHours.reasonForRequest": "リクエストの理由",
    "runningHours.reasonIsRequired": "理由が必要です",
    "runningHours.runningHoursDateInPast": "稼働時間の日付は、最後の更新よりも古い日付にすることはできません。続行する前に正しい日付を選択してください。",
    "runningHours.updateRunningHours": "稼働時間を更新する",
    "runningHours.youCanMakeRequest": "稼働時間の更新をリクエストできます",
    "runningHoursUpdated": "稼働時間を更新しました",
    "save": "保存",
    "saveVisibilitySelection": "保存",
    "searchInsteadFor": "代わりに検索",
    "searchPlaceholder": "検索...",
    "select": "選択",
    "select.sortPlaceholder": "並べ替え",
    "selectInstallation": "設備を選択する",
    "selectInstallation:energy": "発電プラントを選択する",
    "selectInstallation:marine": "船舶を選択する",
    "send": "送信する",
    "sendingFileFailed": "失敗しました",
    "services": "サービス",
    "showMore": "さらに表示する",
    "showingResultsFor": "結果を表示",
    "sorting.createdByAZ": "作成者 (A-Z)",
    "sorting.creationDate": "作成日",
    "sorting.emailShortAZ": "電子メール (A-Z)",
    "sorting.equipmentAZ": "製品 (A-Z)",
    "sorting.installationAZ": "設備 (A-Z)",
    "sorting.modified": "修正済",
    "sorting.nameAZ": "名前 (A-Z)",
    "sorting.newestFirst": "新しい順",
    "sorting.oldestFirst": "古い順",
    "sorting.orderNumber": "注文番号",
    "sorting.poNumber": "PO番号",
    "sorting.relevancy": "関連性が高い順",
    "sorting.requestTypeAZ": "リクエストタイプ (A-Z)",
    "sorting.statusAZ": "ステータス (A-Z)",
    "sorting.subjectAZ": "件名 (A-Z)",
    "sorting.titleAZ": "タイトル (A-Z)",
    "sparePartClaim": "予備部品請求",
    "status": "ステータス",
    "techRequest": "技術サポート",
    "technicalDocumentation": "技術文書",
    "type": "タイプ",
    "typeToFilter": "入力してフィルター処理する...",
    "unit.cycles": "サイクル",
    "unit.hours": "時間",
    "unit.litres": "リットル",
    "unknown": "不明",
    "update": "更新",
    "users": "ユーザー",
    "validation.characterLimit": "文字数制限: {characters}",
    "validation.incorrectPhoneNumber": "電話番号は + で始まり、9～14桁になります",
    "viewContracts": "契約を表示する",
    "visibleInstallations": "表示可能な設備",
    "visibleInstallations.changeAction": "設備の追加または削除をリクエストする",
    "visibleInstallations.changeAction:energy": "発電プラントの追加または削除をリクエストする",
    "visibleInstallations.changeAction:marine": "船舶の追加または削除をリクエストする",
    "visibleInstallations.changeLabel": "設備リストの変更が必要ですか?",
    "visibleInstallations.changeLabel:energy": "発電プラントリストの変更が必要ですか?",
    "visibleInstallations.changeLabel:marine": "船舶リストの変更が必要ですか?",
    "visibleInstallations:energy": "表示可能な発電プラント",
    "visibleInstallations:marine": "表示可能な船舶",
    "warrantyClaim": "保証請求",
    "warrantyEndDate": "保証終了日",
    "year": "年",
    "years": "年",
    "yes": "はい",
  },
};
