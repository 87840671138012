import * as React from "react";

import {
  IAttachment,
  IClaimComment,
  ICombinedRequest,
  ICustomerSupportTicket,
  INotification,
  INotificationObjectData,
  ISparePartClaim,
  ITechRequest,
} from "online-services-types";

import { APIFetch } from "src/APIFetch";
import { APIResourceState } from "src/redux/api";
import { Dispatch } from "redux";
import { IAppState } from "src/redux";
import { RequestType } from "./warrantyClaim";
import { chunk } from "lodash";
import { setNotificationsAsRead } from "src/redux/actions";

interface IDocumentNotificationSubItem {
  id: string;
  mainType: string;
  subType: string;
  documentDate: string;
  documentNumber: string;
  documentIssue: string | null;
  title: string;
  installationIds: string[] | null;
  equipmentIds: string[] | null;
  productReferenceTypes: string[] | null;
  subItems: null;
}

interface ICMReportNotificationSubItem {
  installationIds: string[];
}

export type IDocumentNotification = INotification<IDocumentNotificationSubItem>;
export type ICMReportNotification = INotification<ICMReportNotificationSubItem>;
type IRateableRequest = ICustomerSupportTicket | ITechRequest | ISparePartClaim;

export type INewCommentNotification = INotification<{ message: string }>;

export interface INotificationContext {
  resetNotificationFetchTimeout(): void;
}

export const NotificationContext = React.createContext<INotificationContext>({
  resetNotificationFetchTimeout: () => undefined,
});

export enum ObjectType {
  CMReport = "CMReport",
  SalesOrder = "SalesOrder",
  ServiceQuotation = "ServiceQuotation",
  WarrantyClaim = "WarrantyClaim",
  TechRequest = "TechRequest",
  CustomerSupportTicket = "CustomerSupportTicket",
  SparePartClaim = "SparePartClaim",
  TechnicalKnowledge = "TechnicalKnowledge",
}

export enum NotificationType {
  EquipmentRunningHoursUpdate = "EquipmentRunningHoursUpdate",
  NewCMReport = "NewCMReport",
  NewCSCAttachment = "NewCSCAttachment",
  NewCSCComment = "NewCSCComment",
  NewCSCRequest = "NewCSCRequest",
  NewSalesOrder = "NewSalesOrder",
  NewServiceQuotation = "NewServiceQuotation",
  NewSparePartClaim = "NewSparePartClaim",
  NewTechnicalKnowledge = "NewTechnicalKnowledge",
  NewTechnicalKnowledgeCritical = "NewTechnicalKnowledgeCritical",
  NewTechRequest = "NewTechRequest",
  NewTechRequestAttachment = "NewTechRequestAttachment",
  NewTechRequestByWartsila = "NewTechRequestByWartsila",
  NewTechRequestComment = "NewTechRequestComment",
  NewTechRequestDistributor = "NewTechRequestDistributor",
  NewTechRequestSolutionPlan = "NewTechRequestSolutionPlan",
  ResolvedSparePartClaim = "ResolvedSparePartClaim",
  SalesOrderConfirmed = "SalesOrderConfirmed",
  ServiceQuotationQuoted = "ServiceQuotationQuoted",
  WaitingForRating = "WaitingForRating",
  WaitingForReply = "WaitingForReply",
  WarrantyContactUpdated = "WarrantyContactUpdated",
  NewWarrantyClaim = "NewWarrantyClaim",
  NewWarrantyClaimComment = "NewWarrantyClaimComment",
  NewWarrantyClaimAttachment = "NewWarrantyClaimAttachment",
  NewCMReportBudget = "NewCMReportBudget",
  NewCMReportDMP = "NewCMReportDMP",
  NewCMReportQuarterly = "NewCMReportQuarterly",
  NewCMReportForecasting = "NewCMReportForecasting",
  SalesOrderProformaAvailable = "SalesOrderProformaAvailable",
  ServiceQuotationProformaAvailable = "ServiceQuotationProformaAvailable",
  SalesOrderDeliveryReadyToBeCollected = "SalesOrderDeliveryReadyToBeCollected",
  SalesOrderDeliveryCollected = "SalesOrderDeliveryCollected",
  SalesOrderDeliveryDispatched = "SalesOrderDeliveryDispatched",
  SalesOrderDeliveryDelivered = "SalesOrderDeliveryDelivered",
  SalesOrderDeliveryDocumentAvailable = "SalesOrderDeliveryDocumentAvailable",
  SalesOrderEtaDateChanged = "SalesOrderEtaDateChanged",
  SalesOrderCollectionDateChanged = "SalesOrderCollectionDateChanged",
  NewSWRRecommendation = "NewSWRRecommendation",
  SalesOrderItemClassificationAvailable = "SalesOrderItemClassificationAvailable",
  ServiceQuotationIsAboutToExpire = "ServiceQuotationIsAboutToExpire",

  WarrantyClaimDeliveryCollected = "WarrantyClaimDeliveryCollected",
  WarrantyClaimDeliveryDelivered = "WarrantyClaimDeliveryDelivered",
  WarrantyClaimDeliveryDispatched = "WarrantyClaimDeliveryDispatched",
  WarrantyClaimDeliveryReadyToBeCollected = "WarrantyClaimDeliveryReadyToBeCollected",
  WarrantyClaimDeliveryDocumentAvailable = "WarrantyClaimDeliveryDocumentAvailable",

  NewInvoiceAvailable = "NewInvoiceAvailable",
}

export type NotificationsDataRefresh = "all" | "new" | "none";

const objectTypesWithObjectData = [
  ObjectType.SalesOrder,
  ObjectType.ServiceQuotation,
  ObjectType.WarrantyClaim,
  ObjectType.TechRequest,
  ObjectType.CustomerSupportTicket,
  ObjectType.SparePartClaim,
  ObjectType.TechnicalKnowledge,
];

/**
 * This refreshes the notifications and updates the related requests in case there are
 * new notifications. This ensures the notification status matches with what should be
 * consistent with the notification and contains what the notification needs to display
 * to the user.
 */

export function fetchNotifications(refreshData: NotificationsDataRefresh = "new") {
  return async (dispatch: Dispatch, getState: () => IAppState) => {
    try {
      const notifications = await new APIFetch<INotification[]>(
        "service/notifications",
        undefined,
        undefined,
        undefined,
        undefined,
        true
      ).get();

      if (refreshData !== "none") {
        const oldNotifications = getState().notifications.data || [];
        const newNotifications = notifications.filter(
          (notification) => !oldNotifications.find((old) => old.id === notification.id)
        );
        const notificationsToEnhance = refreshData === "all" ? notifications || oldNotifications : newNotifications;
        await enhanceNotificationsWithObjectData(notifications, notificationsToEnhance, oldNotifications);
      }

      dispatch(APIResourceState.notifications.doneAction(notifications as {} as INotification[]));
    } catch (error) {
      // Comment out toast-related errors until we have some logic to show only relevant errors
      // displayError((error as Error).message);
    }
  };
}

export function clearNotifications() {
  return (dispatch: Dispatch, getState: () => IAppState) => {
    if (!getState().notifications.data) return;
    const activeNotifications = (getState().notifications.data || []).filter((notification) => !notification.discarded);
    dispatch(APIResourceState.notifications.doneAction(activeNotifications as {} as INotification[]));
  };
}

async function enhanceNotificationsWithObjectData(
  allNotifications: INotification[],
  notificationsToEnhance: INotification[],
  oldNotifications: INotification[]
) {
  const objectIdsToFetch = notificationsToEnhance
    .filter((notif) => objectTypesWithObjectData.includes(notif.objectType as ObjectType))
    .map((notif) => notif.objectId);
  let responses: INotificationObjectData[] = [];
  if (objectIdsToFetch.length > 0) {
    // Chunk the ids up to sets of 200 to prevent crashes caused by too many id:s at once
    const idChunks = chunk(objectIdsToFetch, 200);
    responses = await Promise.all(
      idChunks.map((chunk) =>
        new APIFetch<INotificationObjectData>("service/notifications/data").getWithParams({
          objectIds: chunk.join(","),
        })
      )
    );
  }
  const fullMap = Object.assign({}, ...responses);
  const oldMap: INotificationObjectData = oldNotifications.reduce((map, notification) => {
    if (notification.objectId && notification.objectData && !map[notification.objectId])
      map[notification.objectId] = notification.objectData;
    return map;
  }, {});
  for (let notification of allNotifications) {
    if (fullMap[notification.objectId]) {
      notification.objectData = fullMap[notification.objectId];
    } else if (oldMap[notification.objectId]) {
      notification.objectData = oldMap[notification.objectId];
    }
  }
}

/**
 * Discard notification from redux, this WILL NOT trigger a backend event.
 * @param notificationContext Use null if you have absolutely no way to access the context (e.g. componentWellUnmount())
 *    otherwise get it with <NotificationContext.Consumer>
 */
export function discardNotification(notificationIds: string[], notificationContext: INotificationContext | null) {
  return (dispatch: Dispatch) => {
    if (notificationContext) {
      notificationContext.resetNotificationFetchTimeout();
    }
    dispatch(
      APIResourceState.notifications.patchAction(
        notificationIds.map((notificationId) => ({
          id: notificationId,
          discarded: true,
        }))
      )
    );
  };
}

/**
 * Discard notification from redux, this WILL trigger a backend event.
 * @param notificationContext Use null if you have absolutely no way to access the context (e.g. componentWellUnmount())
 *    otherwise get it with <NotificationContext.Consumer>
 */
export function discardAndSetNotificationAsRead(
  notificationIds: string[],
  notificationContext: INotificationContext | null
) {
  return async (dispatch: Dispatch) => {
    if (notificationContext) {
      notificationContext.resetNotificationFetchTimeout();
    }

    dispatch(
      APIResourceState.notifications.patchAction(
        notificationIds.map((notificationId) => ({
          id: notificationId,
          discarded: true,
        }))
      )
    );

    await setNotificationsAsRead(notificationIds);
  };
}

/**
 * NB!
 *
 * Filter out discarded notifications (discarded notifications will stay in redux until next notifications refresh/reload)
 * with this. This should be the default behavior for a notification filter function.
 *
 * The below notification filters should filter out all discarded notifications and provide a separate filter function that
 * also returns the discarded notifications in case there is need for knowing there were notifications for some object. E.g.
 * the requests listing needs to know if there were notifications so that the relevancy sort will not change order immediately
 * after dismissing a notification.
 *
 * For the requests, filterRequestNotificationsIncludingDiscarded() is used to get also the discarded notifications which are
 * needed inside the component.
 */

export function removeDiscardedNotifications<T extends INotification>(notifications: T[]): T[] {
  return notifications.filter((notification) => !notification.discarded);
}

/**
 * Filter the full notification list for request-related notifications
 */

export type RequestWaitingForReplyNotification = INotification<IClaimComment>;
export type RequestWaitingForRatingNotification = INotification;
export type RequestNewAttachmentNotification = INotification<IAttachment>;
export type RequestNotification =
  | RequestWaitingForReplyNotification
  | RequestWaitingForRatingNotification
  | RequestNewAttachmentNotification;

export function filterRequestNotificationsIncludingDiscarded(notifications: RequestNotification[]) {
  const objectTypes = [
    RequestType.TechRequest,
    RequestType.WarrantyClaim,
    RequestType.CustomerSupportTicket,
    RequestType.SparePartClaim,
  ];
  const requestNotifications = notifications.filter((notification) =>
    objectTypes.includes(notification.objectType as RequestType)
  );

  // There are more notifications coming from the backend than what we are showing in the front end, so filter out the ones that shouldn't be shown
  return filterWaitingForRatingNotifications(requestNotifications)
    .concat(filterWaitingForReplyNotifications(requestNotifications))
    .concat(filterNewRequestNotifications(requestNotifications))
    .concat(filterResolvedSparePartNotifications(requestNotifications))
    .concat(filterNewRequestAttachments(requestNotifications))
    .concat(filterSolutionPlanNotifications(requestNotifications));
}

export function filterRequestNotifications(notifications: RequestNotification[]) {
  return removeDiscardedNotifications(filterRequestNotificationsIncludingDiscarded(notifications));
}

/**
 * Filters for rating notifications, item is optional (in case the notifications have been already filtered)
 * @param notifications Notifications from redux
 * @param item Item to match to notifications (optional)
 */

export function filterWaitingForRatingNotifications(
  notifications: INotification[],
  item?: ICombinedRequest
): RequestWaitingForRatingNotification[] {
  return notifications.filter(
    (notification) =>
      notification.type === NotificationType.WaitingForRating &&
      (item === undefined ||
        (notification.objectType === item.requestType &&
          notification.objectId === item.id &&
          (item as IRateableRequest).ratingAllowed) ||
        item.requestType === RequestType.SparePartClaim)
  ) as {} as RequestWaitingForRatingNotification[];
}

/**
 * Filters for reply notifications, item is optional (in case the notifications have been already filtered)
 * @param notifications Notifications from redux
 * @param item Item to match to notifications (optional)
 */

export function filterWaitingForReplyNotifications(
  notifications: INotification[],
  item?: ICombinedRequest
): RequestWaitingForReplyNotification[] {
  const notificationTypes = [
    NotificationType.WaitingForReply,
    NotificationType.NewCSCComment,
    NotificationType.NewTechRequestComment,
    NotificationType.NewWarrantyClaimComment,
  ];
  const relevantNotifs = notifications.filter(
    (notification) =>
      notificationTypes.includes(notification.type as NotificationType) &&
      (item === undefined || (notification.objectType === item.requestType && notification.objectId === item.id))
  ) as {} as RequestWaitingForReplyNotification[];
  // If a request has multiple comment notifications, combine them into one message
  relevantNotifs.sort((a, b) => new Date(a.creationDate).getTime() - new Date(b.creationDate).getTime());
  const notificationsByObjectId: { [objectId: string]: RequestWaitingForReplyNotification } = {};
  for (const notif of relevantNotifs) {
    const notifData = notif.data || { message: "" };
    if (!notificationsByObjectId[notif.objectId]) {
      notificationsByObjectId[notif.objectId] = { ...notif, data: { ...notifData } };
    } else {
      notificationsByObjectId[notif.objectId].data.message += `\n${notifData.message}`;
      // Include each notification's id separated by an underscore
      notificationsByObjectId[notif.objectId].id += `_${notif.id}`;
    }
  }
  return Object.keys(notificationsByObjectId).map((key) => notificationsByObjectId[key]);
}

/**
 * Filters for new request notifications, item is optional (in case the notifications have already been filtered)
 * @param notifications Notifications from redux
 * @param item Item to match to notifications (optional)
 * @param skipDeliveries skip delivery notifications (optional)
 */

export function filterNewRequestNotifications(
  notifications: INotification[],
  item?: ICombinedRequest,
  skipDeliveries?: boolean
): INotification[] {
  let notificationTypes = [
    NotificationType.NewSparePartClaim,
    NotificationType.NewCSCRequest,
    NotificationType.NewTechRequest,
    NotificationType.NewTechRequestByWartsila,
    NotificationType.NewWarrantyClaim,
    NotificationType.NewTechRequestDistributor,
  ];

  if (!skipDeliveries) {
    notificationTypes = notificationTypes.concat([
      NotificationType.WarrantyClaimDeliveryReadyToBeCollected,
      NotificationType.WarrantyClaimDeliveryDispatched,
      NotificationType.WarrantyClaimDeliveryDocumentAvailable,
      NotificationType.WarrantyClaimDeliveryDelivered,
      NotificationType.WarrantyClaimDeliveryCollected,
    ]);
  }

  return notifications.filter(
    (notification) =>
      notificationTypes.includes(notification.type as NotificationType) &&
      (item === undefined || (notification.objectType === item.requestType && notification.objectId === item.id))
  );
}

export function filterResolvedSparePartNotifications(
  notifications: INotification[],
  item?: ICombinedRequest
): INotification[] {
  return notifications.filter(
    (notification) =>
      notification.objectType === RequestType.SparePartClaim &&
      notification.type === NotificationType.ResolvedSparePartClaim &&
      (item === undefined || notification.objectId === item.id)
  );
}

export function filterNewRequestAttachments(
  notifications: INotification[],
  item?: ICombinedRequest
): RequestNewAttachmentNotification[] {
  const notificationTypes = [
    NotificationType.NewCSCAttachment,
    NotificationType.NewTechRequestAttachment,
    NotificationType.NewWarrantyClaimAttachment,
  ];
  return notifications.filter(
    (notification) =>
      notificationTypes.includes(notification.type as NotificationType) &&
      (item === undefined || (notification.objectType === item.requestType && notification.objectId === item.id))
  ) as {} as RequestNewAttachmentNotification[];
}

export function filterDeliveryNotifications(notifications: INotification[]): INotification[] {
  const notificationTypes = [
    NotificationType.WarrantyClaimDeliveryReadyToBeCollected,
    NotificationType.WarrantyClaimDeliveryDispatched,
    NotificationType.WarrantyClaimDeliveryDocumentAvailable,
    NotificationType.WarrantyClaimDeliveryDelivered,
    NotificationType.WarrantyClaimDeliveryCollected,
  ];
  return notifications.filter((notification) => notificationTypes.includes(notification.type as NotificationType));
}

export function filterSolutionPlanNotifications(
  notifications: INotification[],
  item?: ICombinedRequest
): INotification[] {
  return notifications.filter(
    (notification) =>
      notification.type === NotificationType.NewTechRequestSolutionPlan &&
      (item === undefined || notification.objectId === item.id)
  );
}

export function filterCMReportNotifications(notifications: INotification[]): INotification[] {
  const notificationTypes = [
    NotificationType.NewCMReport,
    NotificationType.NewCMReportBudget,
    NotificationType.NewCMReportDMP,
    NotificationType.NewCMReportQuarterly,
    NotificationType.NewCMReportForecasting,
  ];
  return removeDiscardedNotifications(notifications)
    .filter((notification) => notificationTypes.includes(notification.type as NotificationType))
    .filter((notification) => notification.objectType === ObjectType.CMReport);
}

export function filterQuotationNotifications(notifications: INotification[]): INotification[] {
  const objectTypes = [ObjectType.SalesOrder, ObjectType.ServiceQuotation];
  return removeDiscardedNotifications(notifications).filter((notification) =>
    objectTypes.includes(notification.objectType as ObjectType)
  );
}

const documentNotificationTypes = [
  NotificationType.NewTechnicalKnowledge,
  NotificationType.NewTechnicalKnowledgeCritical,
  NotificationType.NewSWRRecommendation,
];

export function filterDocumentNotifications(notifications: INotification[]): IDocumentNotification[] {
  notifications = removeDiscardedNotifications(notifications);
  return notifications
    .filter((notification) => documentNotificationTypes.includes(notification.type as NotificationType))
    .map((n) => ({
      ...n,
      data: n.data || {
        mainType: "",
        subType: "",
        documentNumber: "",
        documentIssue: null,
        documentDate: "",
        title: "",
      },
    })) as {} as IDocumentNotification[];
}

export const filterNotificationsByDocumentType = (notifications: IDocumentNotification[], documentType: string) => {
  return removeDiscardedNotifications(notifications).filter(
    (notification) => notification.data.mainType === documentType
  );
};

export const filterNotificationsByDocumentSubType = (notifications: IDocumentNotification[], subType: string) => {
  return removeDiscardedNotifications(notifications).filter((notification) => notification.data.subType === subType);
};
