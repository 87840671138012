// Wärtsilä Online translations exported from Salesforce. Please see "Translations" in Confluence for correct workflow.
import { ITranslations } from "../../localization";

// tslint:disable-next-line: variable-name
export const main_ru: ITranslations = {
  ru: {
    "account.accountSelected": "Выбрана учетная запись",
    "account.selectingAccount": "Выбирается учетная запись...",
    "addAttachment": "Добавить приложение",
    "addFilter": "Добавить фильтр",
    "addInstallation": "Добавить установку",
    "addInstallation.nameIsRequired": "Требуется имя",
    "addInstallation:energy": "Добавить электростанцию",
    "addInstallation:marine": "Добавить судно",
    "applyFilter": "Применить фильтр",
    "attachments": "Приложения",
    "auth.continueToUseOS": "Продолжить использование Wärtsilä Online",
    "auth.logout": "Выход из системы",
    "auth.readOnlyMode": "Режим чтения",
    "auth.redirectingToLogin": "Перенаправление для входа в систему...",
    "auth.sessionExpired": "Истекло время ожидания сеанса, произойдет перенаправление на страницу входа в систему",
    "auth.sessionWillExpire": "Ваш сеанс скоро закончится",
    "button.cancel": "Cancel (Отмена)",
    "button.close": "Закрыть",
    "button.next": "Следующее",
    "button.previous": "Предыдущее",
    "button.saveForLater": "Сохранить для дальнейшего применения",
    "button.send": "Отправьте",
    "button.skip": "Пропустить",
    "button.updateDraft": "Обновить черновик",
    "cancel": "Cancel (Отмена)",
    "change": "Изменить",
    "changePasswordDialog.success": "Сообщение с инструкцией успешно отправлено.",
    "chooseDocumentType": "Фильтровать документы",
    "chooseFilter": "Выбрать фильтр…",
    "clearFilters": "Очистить фильтры",
    "comment.commentIsRequired": "Требуется примечание",
    "comment.postComment": "Оставить примечание",
    "comment.replyComment": "Ответить",
    "comment.writeYourComment": "Написать свое примечание",
    "comment.writeYourReply": "Написать свой ответ",
    "comment.you": "Вы",
    "commenting": "Добавление примечаний",
    "contact": "Контакты",
    "contact.contactWartsilaCustomerSupport": "Свяжитесь со службой поддержки заказчика компании Wärtsilä",
    "contact.subject": "Предмет",
    "contact.subjectIsRequired": "Требуется предмет",
    "contact.yourMessage": "Ваше сообщение",
    "contact.yourMessageIsRequired": "Требуется сообщение",
    "contactCustomerSupport": "Свяжитесь со службой поддержки заказчика",
    "contextHelp": "Краткий обзор",
    "customerSupportTicket": "Запрос в службу поддержки заказчика",
    "date": "Дата",
    "delete": "Удалить",
    "didYouMean": "Вы имели в виду:",
    "dismissAll": "Отклонить все",
    "download.downloadReady": "Загрузка готова!",
    "download.equipmentNotFound": "Не удается найти артикул для изделия",
    "download.error": "Произошла ошибка при загрузке",
    "download.preparingDownload": "Подготовка загрузки",
    "draft.confirmDelete": "Вы действительно хотите удалить этот черновик?",
    "draft.confirmDeleteTitle": "Удалить черновик?",
    "draft.continueEditing": "Продолжить редактирование",
    "draft.delete": "Удалить черновик",
    "edit": "Правка",
    "engine": "Двигатель",
    "equipment": "Продукт",
    "equipment.configuration": "Конфигурация",
    "equipment.nickname": "Псевдоним изделия",
    "equipment.performanceData": "Производительность",
    "equipment.title": "Название",
    "equipment.validation.nicknameTooLong": "Максимальная длина псевдонима {maxLength}",
    "equipmentNotFound": "Нет существующих изделий",
    "error.networkError": "Ошибка сети",
    "error.networkErrorPleaseCheckConnectivity": "Проверьте возможность соединения.",
    "error.readOnlyErrorMsg": "Вы в режиме только для чтения.",
    "error.readOnlyErrorTitle": "Ошибка только для чтения",
    "fileUpload.attachmentRejected": "Приложение отклонено. Проверьте ограничения выгрузки.",
    "fileUpload.noContactPerson": "Нет контактного лица. Не удается отправить сообщение!",
    "fileUpload.spareParts.limit": "Максимальный размер выгружаемого файла: 5 МБ",
    "fileUpload.uploadAborted": "Выгрузка файла прервана",
    "fileUpload.uploadFailed": "Выгрузка файла не выполнена",
    "fileUpload.userHasReadOnlyAccess": "У пользователя доступ только для чтения",
    "filterRequests": "Фильтровать запросы",
    "iDontKnow": "Я не знаю",
    "index.installationDetails": "Подробные данные по установке",
    "installation": "Установка",
    "installation.classificationSociety": "Классификационное общество",
    "installation.deliveryDate": "Дата поставки",
    "installation.description": "Описание",
    "installation.imo": "IMO",
    "installation.inspectionDate": "Дата осмотра",
    "installation.name": "Название",
    "installation.nextPlannedMaintenance": "Следующее плановое техническое обслуживание",
    "installation.type": "Тип",
    "installation:energy": "Электростанция",
    "installation:marine": "Судно",
    "installations": "Установки",
    "installations:energy": "Электростанции",
    "installations:marine": "Суда",
    "language": "Язык",
    "language.languageNotFound": "Не удалось найти язык по коду языка",
    "languageDisclaimer": "Если подать этот запрос на английском языке, то это ускорит процесс обработки",
    "letsStart": "Начнем",
    "loading": "Загрузка...",
    "logout": "Выход",
    "main.loadMore": "Загрузить еще",
    "main.pageNotFound": "Искомый URL-адрес не найден: {url}",
    "main.pageNotFoundTitle": "Не найден",
    "manufacturerSerialNumber": "Серийный номер изготовителя",
    "month.april": "Апрель",
    "month.august": "Август",
    "month.december": "Декабрь",
    "month.february": "Февраль",
    "month.january": "Январь",
    "month.july": "Июль",
    "month.june": "Июнь",
    "month.march": "Март",
    "month.may": "Май",
    "month.november": "Ноябрь",
    "month.october": "Октябрь",
    "month.september": "Сентябрь",
    "months": "месяцев",
    "myFleet": "Мои установки",
    "myFleet:marine": "Мой флот",
    "myInstallations": "Мои установки",
    "myInstallations:energy": "Мои электростанции",
    "myInstallations:marine": "Мои суда",
    "myProfile": "Мой профиль",
    "news.furtherQuestions": "Дополнительные примечания или вопросы",
    "news.productsAreRequired": "Требуется изделие(я)",
    "news.quotationRequestSent": "Запрос на котировку отправлен.",
    "news.readMore": "Читать далее",
    "news.requestQuotation": "Запросить котировку",
    "news.selectTheProductsForTheQuotation": "Выбрать изделие(я) для получения котировки",
    "news.sendingQuotationRequest": "Отправка запроса на котировку.",
    "news.youAreAskingQuotationFor": "Вы запрашиваете котировку на {productName}.",
    "nickname": "Псевдоним",
    "no": "Нет",
    "noDocumentsFound": "Не найдены документы для",
    "noVisibleInstallationsFound": "Вы не определили какой-либо видимый {sector}",
    "noVisibleInstallationsFoundHelp": "Это можно сделать из {sector}",
    "notApplicable": "Не относится",
    "notificationList.markedNotificationsAsRead": "Уведомления {count} отмечены как прочитанные",
    "notificationList.title.criticalBulletinUpdated": "Бюллетень для принятия срочных мер: {title}",
    "notificationList.title.equipmentRunningHoursUpdate": "Наработку в часах следует обновить",
    "notificationList.title.manualUpdated": "Глава с руководством обновлена: {title}",
    "notificationList.title.newAttachment": "Новое приложение",
    "notificationList.title.newCMReport": "Новый отчет",
    "notificationList.title.newComment": "Новое примечание",
    "notificationList.title.newRequest": "Создан новый запрос",
    "notificationList.title.newSalesOrder": "Новый заказ на закупку",
    "notificationList.title.newServiceQuotation": "Новая котировка на обслуживание",
    "notificationList.title.newSparePartClaim": "Рекламация на новую запасную часть",
    "notificationList.title.resolvedSparePartClaim": "Урегулированная рекламация на запасную часть",
    "notificationList.title.salesOrderConfirmed": "Заказ на закупку подтвержден",
    "notificationList.title.serviceQuotationQuoted": "Котировка на обслуживание предоставлена",
    "notificationList.title.techRequestSolutionPlan": "Добавлен новый план решений",
    "notificationList.title.waitingForRating": "Ожидание оценки",
    "notificationList.title.waitingForReply": "Ожидание ответа",
    "notificationList.title.warrantyContactUpdated": "Гарантийный контракт обновлен",
    "notifications.lastWeek": "На прошлой неделе",
    "notifications.markAllRead": "Отметить все как прочитанное",
    "notifications.older": "Раньше",
    "notifications.thisWeek": "На этой неделе",
    "notifications.title": "Уведомления ({unreadNotifications})",
    "notifications.today": "Сегодня",
    "notifications.yesterday": "Вчера",
    "ok": "В ПОРЯДКЕ",
    "optionalFieldTitle": "как опция",
    "orderBeingProcessed": "Заказ обрабатывается",
    "phone": "Телефон",
    "pleaseAddInstallation": "Выберите установку!",
    "pleaseAddInstallation:energy": "Выберите электростанцию!",
    "pleaseAddInstallation:marine": "Выберите судно!",
    "productNumber": "Номер изделия",
    "productType": "Тип изделия",
    "profile.updating": "Обновление профиля…",
    "quotationBeingProcessed": "Котировка обрабатывается",
    "removeInstallation": "Удалить установку",
    "removeInstallation:energy": "Удалить электростанцию",
    "removeInstallation:marine": "Удалить судно",
    "request.draftSaved": "Черновик сохранен",
    "request.draftSavingError": "Ошибка при сохранении черновика",
    "request.savingDraft": "Сохранение черновика...",
    "request.send": "Отправить запрос",
    "request.sending": "Отправление запроса...",
    "request.sendingError": "Ошибка при отправлении запроса",
    "request.sent": "Запрос отправлен.",
    "routes.contactContractManager": "Свяжитесь с менеджером по контрактам",
    "routes.contracts": "Соглашения",
    "routes.download": "Загрузки",
    "routes.equipment": "Продукт",
    "routes.fluidReportDraft": "Новый отчет по жидкостям",
    "routes.installation": "Установка",
    "routes.installationSelection": "Выбрать установку",
    "routes.installationSelection:energy": "Выбрать электростанцию",
    "routes.installationSelection:marine": "Выбрать судно",
    "routes.newFluidReport": "Новый отчет по жидкостям",
    "routes.newRequest": "Новый запрос",
    "routes.newSFOCReport": "Новый отчет о рабочих характеристиках SFOC",
    "routes.news": "Новости",
    "routes.partsCatalogue": "Просмотреть запасные части",
    "routes.performance": "Производительность",
    "routes.placeOrder": "Разместить заказ",
    "routes.proceedToOrder": "Приступить к оформлению заказа",
    "routes.reports": "Отчеты",
    "routes.requestQuotation": "Запросить котировку",
    "routes.requests": "Поддержка",
    "routes.runningHours": "Часы наработки",
    "routes.spareParts": "Коммерческая деятельность",
    "routes.technicalDocuments": "Технические документы",
    "routes.users": "Пользователи",
    "runningHours": "Часы наработки",
    "runningHours.confirmDialogMessageHigh": "Вы ввели значения наработки в часах, которые выше, чем время, истекшее после последнего обновления. Вы хотите обновить новую запись?",
    "runningHours.confirmDialogMessageLow": "Вы ввели значение наработки в часах, которое ниже, чем текущее значение. Вы можете сделать запрос на обновление наработки в часах.",
    "runningHours.invalidValue": "Недействительное значение",
    "runningHours.lowValue": "Вы ввели значение наработки в часах, которое ниже, чем текущее значение.",
    "runningHours.makeRequest": "Запросить",
    "runningHours.newRunningHours": "Новая наработка в часах",
    "runningHours.reasonForLowerValue": "Причина для более низкого значения",
    "runningHours.reasonForRequest": "Причина для запроса",
    "runningHours.reasonIsRequired": "Требуется причина",
    "runningHours.runningHoursDateInPast": "Дата наработки в часах не может быть раньше, чем дата последнего обновления, выберите правильную дату, перед тем как продолжить.",
    "runningHours.updateRunningHours": "Обновить наработку в часах",
    "runningHours.youCanMakeRequest": "Вы можете сделать запрос на обновление наработки в часах",
    "runningHoursUpdated": "Наработка в часах обновлена",
    "save": "Сохранить",
    "saveVisibilitySelection": "Сохранить",
    "searchInsteadFor": "Вместо этого искать",
    "searchPlaceholder": "Поиск...",
    "select": "Выбрать",
    "select.sortPlaceholder": "Сортировать по",
    "selectInstallation": "Выбрать установку",
    "selectInstallation:energy": "Выбрать электростанцию",
    "selectInstallation:marine": "Выбрать судно",
    "send": "Отправьте",
    "sendingFileFailed": "Не выполнено",
    "services": "Сервисное обслуживание",
    "showMore": "Показать еще",
    "showingResultsFor": "Отображение результатов для",
    "sorting.createdByAZ": "Создано (кем) (A-Z)",
    "sorting.creationDate": "Дата создания",
    "sorting.emailShortAZ": "Электронная почта (A-Z)",
    "sorting.equipmentAZ": "Изделие (A-Z)",
    "sorting.installationAZ": "Установка (A-Z)",
    "sorting.modified": "Изменено",
    "sorting.nameAZ": "Имя (A-Z)",
    "sorting.newestFirst": "Сначала самое новое",
    "sorting.oldestFirst": "Сначала самое старое",
    "sorting.orderNumber": "Номер заказа",
    "sorting.poNumber": "Номер заказа на поставку",
    "sorting.relevancy": "Наиболее актуальное",
    "sorting.requestTypeAZ": "Тип запроса (A-Z)",
    "sorting.statusAZ": "Состояние (A-Z)",
    "sorting.subjectAZ": "Предмет (A-Z)",
    "sorting.titleAZ": "Название (A-Z)",
    "sparePartClaim": "Рекламация на запасную часть",
    "status": "Состояние",
    "techRequest": "Техническая поддержка",
    "technicalDocumentation": "Технические документы",
    "type": "Тип",
    "typeToFilter": "Ввести для фильтрации...",
    "unit.cycles": "циклов",
    "unit.hours": "часов",
    "unit.litres": "литров",
    "unknown": "Неизвестный",
    "update": "Обновление",
    "users": "Пользователи",
    "validation.characterLimit": "Ограничение по количеству символов: {characters}",
    "validation.incorrectPhoneNumber": "Номер телефона должен начинаться с + и содержать от 9 до 14 цифр",
    "viewContracts": "Просмотреть соглашения",
    "visibleInstallations": "Видимые установки",
    "visibleInstallations.changeAction": "Запросить добавление или удаление установки",
    "visibleInstallations.changeAction:energy": "Запросить добавление или удаление электростанции",
    "visibleInstallations.changeAction:marine": "Запросить добавление или удаление судна",
    "visibleInstallations.changeLabel": "Требуются ли изменения в списках установок?",
    "visibleInstallations.changeLabel:energy": "Требуются ли изменения в списках электростанций?",
    "visibleInstallations.changeLabel:marine": "Требуются ли изменения в списках судов?",
    "visibleInstallations:energy": "Видимые электростанции",
    "visibleInstallations:marine": "Видимые суда",
    "warrantyClaim": "Гарантийная рекламация",
    "warrantyEndDate": "Дата окончания гарантии",
    "year": "год",
    "years": "года (лет)",
    "yes": "Да",
  },
};
